import { useEffect } from "react";
import BrownButton from "../../buttons/BrownButton";
import Headers from "./Headers";
import { Grid, Typography, useTheme } from "@mui/material";

const LeftContent = ({ pageData }) => {
  // const [headers, setHeaders] = useState();
  // const [body, setBody] = useState();
  const theme = useTheme();

  useEffect(() => {
    // getPageData();
  }, []);

  return (
    <div className="landing wrapper-form-landing">
      {/* <Headers headers={pageData?.header} /> */}
      <div className="landing__content">
        {/* <p> {pageData?.content} </p> */}
        <div className="landing__CTA">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                fontWeight={700}
                marginBottom={2}
                color={theme.palette.black}
              >
                Join the Boho Community of Vendors!
              </Typography>
              <Typography variant="body" marginBottom={2}>
                We are a pop-up artisan market that brings local, handcrafted,
                and vintage items together to open-air venues across Texas. If
                you're looking to join a community of small businesses intent on
                supporting each other, click the button below and start the
                onboarding process.
              </Typography>
            </Grid>
            <Grid item xs={12} marginTop={1}>
              <BrownButton
                paddingLeftRight={157}
                link="/signup"
                content="Join Our Community"
              />
            </Grid>
            <Grid item xs={12}>
              <BrownButton
                paddingLeftRight={157}
                link="/vendor-faq"
                content="Vendor FAQ"
              />
            </Grid>
            <Typography variant="body" marginBottom={2} marginTop={3}>
            <b>QUICK NOTE:</b> While we cheer on all small businesses, The Boho Market's 
            mission is to champion and support locally made, handcrafted, ethically 
            sourced, and vintage small businesses. We ask that all Boho vendors 
            have a hand in creating, designing, or curating their collection, 
            and do our best to ensure all goods arrive at Boho events under fair 
            trade and ethical sourcing. <br/><br/>
            <b>Unfortunately, we do not accept the following vendors:
            <li>MLM</li>
            <li>Direct Sales</li>
            <li>Non-product-based businesses</li>
            <li>Drop-shipped items</li>
            <li>Food items that need to be prepared onsite or are made to order</li>
            </b>
            </Typography>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default LeftContent;
